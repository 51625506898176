import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import React from "react"
import * as contactUsStyles from "./contactUs.module.scss"
import OverlayModal from "./overlayModal"

const ContactUs = () => {
  return (
    <div>
      <OverlayModal />
      <section className={contactUsStyles.grid} role="group">
        {/* contact */}
        <div className={contactUsStyles.contactWrapper} aria-label="Contact Us">
          <h2 className={contactUsStyles.contactHeader}>Contact Us</h2>
          <div className={contactUsStyles.phoneWrapper} role="group">
            <StaticImage
              src="../../images/index/doctor-phone.png"
              placeholder="blurred"
              className={contactUsStyles.doctorPhone}
              alt="Illustration of a man in a desk, wearing a suit, with an eyeball head, holding a telephone. "
            />
            <div
              className={contactUsStyles.uCity}
              aria-label="University City Contact"
            >
              <h3 className={` ${contactUsStyles.uCityHeader}`}>
                UNIVERSITY CITY
              </h3>
              <a
                href="tel:2153865953"
                className={contactUsStyles.uCityPhone}
                title="Call University City"
              >
                215-386-5953
              </a>
            </div>
            <div
              className={contactUsStyles.centerCity}
              aria-label="Center City Contact"
            >
              <h3 className={contactUsStyles.centerCityHeader}>CENTER CITY</h3>
              <a
                href="tel:2159223300"
                className={contactUsStyles.centerCityPhone}
                title="Call Center City"
              >
                215-922-3300
              </a>
            </div>
            <div className={contactUsStyles.emailWrapper} aria-label="Email Us">
              <Link
                to="/contact"
                className={contactUsStyles.emailClick}
                title="Email Us"
              >
                Click here for email addresses
              </Link>
            </div>
            <div className={contactUsStyles.mobileEmailWrapper}>
              <Link className={contactUsStyles.mobileEmail} to="/contact">
                tap{" "}
                <span className={contactUsStyles.mobileEmailHere}>here</span>{" "}
                for email
              </Link>
            </div>
          </div>
        </div>
        {/* hours */}
        <div className={contactUsStyles.hoursWrapper} aria-label="Store Hours">
          <div className={contactUsStyles.hoursHeaderWrapper}>
            <h2 className={contactUsStyles.hoursHeader}>Hours</h2>
          </div>
          <div className={contactUsStyles.hourglassGrid}>
            <ul className={contactUsStyles.hourText} role="group">
              <li aria-label="Weekday Hours">
                <span>Monday through Friday</span> <br />
                10am - 6pm <br />
              </li>
              <li aria-label="Saturday Hours">
                <span>Saturdays</span> <br />
                10am - 5pm <br />
              </li>
            </ul>
            <StaticImage
              className={contactUsStyles.hourglass}
              src="../../images/index/hourglass.png"
              placeholder="blurred"
              alt="Illustration of an hourglass with sand pouring from top section into bottom"
            />
          </div>
        </div>
      </section>
    </div>
  )
}

export default ContactUs
